@import '../../theme/var.scss';

.container {
    font-family: Roboto, serif;
    display: flex;
    flex-direction: column;
    width: auto;

    .tabs {
        margin-bottom: 30px;
        align-self: center;
        font-weight: bold;

        .tab {
            box-sizing: border-box;
            padding: 0 30px;
            font-size: 40px;
            font-weight: bold;
        }

        &:global(.MuiTab-textColorPrimary) {
            font-size: 25px;
        }
    }

    .indicator {
        height: 4px;
    }
}

@include respond-below(xs) {
    .container {
        .tabs {
            .tab {
                padding: 0 15px;
                font-size: 25px;
            }

            &:global(.MuiTab-textColorPrimary) {
                font-size: 20px;
            }
        }
    }
}
