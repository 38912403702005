@import '../../theme/var.scss';

.container {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    font-size: 15px;

    .lastPage {
        padding: 1em;
        justify-content: start;
        font-weight: bold;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 5px;
        margin-bottom: 30px;

        .title {
            font-family: Roboto, serif;
            font-size: 30px;
            font-weight: bold;
        }

        .button {
            border: 2px solid $primary-color;
            color: $primary-color;

            .anchor {
                text-decoration: none;
                color: inherit;
                font-size: inherit;
            }
        }
    }

    .row {
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        gap: 40px;

        .img {
            width: 100%;
            max-width: 440px;
            max-height: 623.33px;
        }
    }
}

@include respond-below(xs) {
    .container {
        .header {
            flex-direction: column;
            gap: 15px;

            .title {
                font-size: 25px;
            }

            .button {
                font-size: 10px;
            }
        }

        .row {
            padding: 0;
            flex-direction: column;
        }
    }
}
