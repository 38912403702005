@import "../../theme/var.scss";

.container {
  background-color: $primary-color;
  display: flex;
  flex-direction: column;
  color: $secondary-color;
  padding: 0 2em;
  font-family: Roboto, serif;

  .icon {
    width: 180px;

    @include respond-below(xs) {
      width: 130px;
    }
  }

  .mediaIcon {
    display: flex;

    .anchor {

      .img {
        width: 2em;
        fill: $secondary-color;
        margin: 0.5em;
        @include respond-below(xs) {
          width: 25px;
        }
      }
    }

  }

  .contactUs {
    font-family: Roboto, serif;
    margin: 0.5em;
    display: flex;
    flex-direction: column;
    padding: 0 0 10px 0;
    @include respond-below(xs) {
      font-size: 15px;
    }

    .title {
      font-size: 25px;
      font-weight: bold;
      padding: inherit;
    }

    .contactUsComponent {
      display: flex;
      flex-direction: row;
      padding: inherit;
      align-items: center;
      margin-right: 3px;

      .startIcon {
        width: 20px;
        margin-right: 10px;
      }

      .anchor {
        color: $secondary-color;
        text-decoration: none;
      }
    }
  }

  .divider {
    background-color: $secondary-color;
  }

  .claims {
    padding: 10px 0;
  }
}