@import "../../theme/var.scss";

.container {
  display: flex;
  flex-direction: column;
  .header {
    //padding-left: $margin-30;

  }

  .content {
    align-self: center;
  }

  .footer {

  }

}
@media (min-width: 576px) {
  .container {
    //max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    //max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    //max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    //max-width: 1140px;
  }
}