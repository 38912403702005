@import "../../theme/var.scss";

.campaignList {
  padding: 0 2em 1em 2em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1em 4em;

  @include respond-below(xs) {
    width: 100%;
    grid-template-columns: 1fr;
    box-sizing: border-box;
  }
}