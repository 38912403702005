@import '../../theme/var.scss';

.campaignItem {
    display: flex;
    flex-direction: column;
    align-items: center;

    .banner {
        img {
            width: 100%;
            max-width: 440px;
            max-height: 623.33px;

            // @include respond-below(xs) {
            //   max-width: 350px;
            //   max-height: 450px;
            // }
        }
    }

    .title {
        padding-bottom: 0.5em;
        font-size: 30px;
        font-weight: bold;
        font-family: Roboto, serif;
        text-align: center;
    }
}

@include respond-below(xs) {
    .campaignItem {
        .title {
            font-size: 24px;
        }
    }
}
