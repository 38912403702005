@import './mixins';

$primary-color: #009E96;
$secondary-color: #FFFFFF;

$breakpoints: (
        xs: 576px,
        sm: 768px,
        md: 992px,
        lg: 1024px,
        xl: 1400px,
);

$mcc-logo-148: 150px;

@include respond-below(xs) {
  $mcc-logo-148: 148px;
}

@include respond-below(md) {
  $mcc-logo-148: 148px;
}
@include respond-below(sm) {
  $mcc-logo-148: 148px;
}
@include respond-below(xl) {
  $mcc-logo-148: 148px;
}

//// fonts
//$font-size-1: 12px;
//$font-size-2: 14px;
//$font-size-3: 16px;
//$font-size-4: 24px;
//$font-size-5: 36px;
//$font-size-6: 48px;
//$font-size-7: 64px;
//$font-size-8: 96px;
//
//// height
//$row-height: 48px;
//
//// color
//$primary-color: white;
//$primary-font-color: #666;
//$error-color: #FF2A00;
//$correct-color: #00AF51;
//$secondary-font-color: #f7971e;
//$hint-font-color: rgba(0, 0, 0, 0.54);
//$disable-color: rgba(0, 0, 0, 0.23);
//
//$box-shadow: 2px 1px 15px -3px rgba(247, 151, 30, 0.5) !important;