@import '../../theme/var.scss';

.container {
    background-color: $primary-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    box-sizing: border-box;
    height: 70px;

    .mccLogo {
        width: $mcc-logo-148;
    }

    .tel {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 24px;
        color: #fff;

        .anchor {
            color: $secondary-color;
            text-decoration: none;
        }

        .icon {
            width: 24px;
        }
    }
}

@include respond-below(xs) {
    .container {
        padding: 0 10px;

        .mccLogo {
            width: 130px;
        }

        .tel {
            font-size: 18px;

            .icon {
                width: 18px;
            }
        }
    }
}
